<template>
  <div class="error-page">
    <div class="main-wrapper">
      <div class="error-box">
        <h1>404</h1>
        <h3><i class="fa fa-warning"></i> Oops! Page not found!</h3>
        <p>The page you requested was not found.</p>
        <router-link to="/" class="btn btn-custom">Back to Home</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error404'
};
</script>
